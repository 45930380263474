// @plugins pwa-update.client.ts
export default defineNuxtPlugin(() => {
  const showUpdateToast = () => {
    /* eslint-disable no-console */
    console.info('PWA Update: Showing update toast notification');
    /* eslint-enable no-console */

    const toaster = useToaster();

    // Use the standard toast with clear instructions
    toaster.add(
      'success',
      'A new version of the application is available. Please refresh the page to update.',
      3600 * 1000,
      'Update Available',
      'pwa-update',
    );
  };

  // Only run in client (browser) environment
  if (process.client) {
    // Expose the test function in non-production environments
    if (process.env.NODE_ENV !== 'production') {
      /* eslint-disable no-console */
      console.info(
        'PWA Update: Exposing test function as window.__testPwaUpdate',
      );
      /* eslint-enable no-console */

      // @ts-ignore
      window.__testPwaUpdate = showUpdateToast;
    }

    // Only run service worker logic if supported
    if ('serviceWorker' in navigator) {
      /* eslint-disable no-console */
      console.info(
        'PWA Update Plugin: Initializing service worker event listeners',
      );
      /* eslint-enable no-console */

      // Listen for the 'controllerchange' event, which fires when a new service worker takes control
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        /* eslint-disable no-console */
        console.info('PWA Update: New service worker has taken control');
        /* eslint-enable no-console */
      });

      // Listen for messages from the service worker
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'VITE_SW_UPDATE') {
          /* eslint-disable no-console */
          console.info('PWA Update: New content is available', event.data);
          /* eslint-enable no-console */
          showUpdateToast();
        }
      });

      // Register a callback to be notified when a new service worker is available
      if ('viteServiceWorker' in window) {
        // @ts-ignore - viteServiceWorker is injected by the PWA plugin
        window.viteServiceWorker.registerUpdateCallback(() => {
          /* eslint-disable no-console */
          console.info(
            'PWA Update: registerUpdateCallback triggered - new version available',
          );
          /* eslint-enable no-console */
          showUpdateToast();
        });
      }

      // Check for waiting worker on existing registration
      navigator.serviceWorker
        .getRegistration()
        .then((registration) => {
          if (registration) {
            /* eslint-disable no-console */
            console.info('PWA Update: Service worker registration found', {
              scope: registration.scope,
              updateViaCache: registration.updateViaCache,
              active: !!registration.active,
              installing: !!registration.installing,
              waiting: !!registration.waiting,
            });
            /* eslint-enable no-console */

            // If there's already a waiting worker, show update notification
            if (registration.waiting) {
              /* eslint-disable no-console */
              console.info('PWA Update: Found waiting service worker');
              /* eslint-enable no-console */
              showUpdateToast();
            }

            // Add listener for future waiting workers
            registration.addEventListener('updatefound', () => {
              /* eslint-disable no-console */
              console.info('PWA Update: Update found event fired');
              /* eslint-enable no-console */

              const newWorker = registration.installing;

              if (newWorker) {
                newWorker.addEventListener('statechange', () => {
                  /* eslint-disable no-console */
                  console.info(
                    'PWA Update: Service worker state changed to',
                    newWorker.state,
                  );
                  /* eslint-enable no-console */

                  if (
                    newWorker.state === 'installed' &&
                    navigator.serviceWorker.controller
                  ) {
                    /* eslint-disable no-console */
                    console.info(
                      'PWA Update: New service worker installed and waiting',
                    );
                    /* eslint-enable no-console */
                    showUpdateToast();
                  }
                });
              }
            });
          } else {
            /* eslint-disable no-console */
            console.info('PWA Update: No service worker registration found');
            /* eslint-enable no-console */
          }
        })
        .catch((error) => {
          console.error(
            'PWA Update: Error checking service worker registration',
            error,
          );
        });
    }
  }
});
