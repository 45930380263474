import { config, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Free solid icons
import {
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faAward as fasAward,
  faBars,
  faBath as fasBath,
  faBed as fasBed,
  faBell,
  faBuilding,
  faCalendar,
  faChartColumn,
  faChartPie,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowDown,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  faCloudArrowUp,
  faCopy,
  faDumbbell,
  faEllipsisH,
  faEllipsisVertical as fasEllipsisVertical,
  faEnvelope,
  faEquals as fasEquals,
  faExclamation,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileArrowDown,
  faFilePdf as fasFilePdf,
  faFilter,
  faGear,
  faGift,
  faHouse,
  faHouseSignal as fasHouseSignal,
  faInfo,
  faLink,
  faLock,
  faMagnifyingGlass,
  faMask,
  faMoon,
  faMoneyBill,
  faPause,
  faPenToSquare,
  faPen,
  faPhone,
  faPlay as fasPlay,
  faPlus,
  faRocket,
  faRotateLeft,
  faRotateRight,
  faSpinner,
  faStar,
  faSterlingSign as fasSterlingSign,
  faTableList,
  faTrashCan,
  faTriangleExclamation,
  faTimesCircle,
  faUpload as fasUpload,
  faUser,
  faUserGroup,
  faWrench,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

// https://fontawesome.com/docs/web/use-with/vue/add-icons#same-icon-different-styles
// Pro regular icons
import {
  faArrowDown as farArrowDown,
  faArrowRightFromBracket as farArrowRightFromBracket,
  faArrowsRotate as farArrowsRotate,
  faArrowUp as farArrowUp,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faBan as farBan,
  faBath as farBath,
  faBed as farBed,
  faBell as farBell,
  faBolt as farBolt,
  faBook as farBook,
  faBuilding as farBuilding,
  faBuildings as farBuildings,
  faCalendar as farCalendar,
  faCalendars as farCalendars,
  faChartBar as farChartBar,
  faChartColumn as farChartColumn,
  faChartLine as farChartLine,
  faChartMixed as farChartMixed,
  faChartPie as farChartPie,
  faChartUser as farChartUser,
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
  faCircle as farCircle,
  faCircleCheck as farCircleCheck,
  faCircleDashed as farCircleDashed,
  faCircleInfo as farCircleInfo,
  faCircleQuestion as farCircleQuestion,
  faCircleSterling as farCircleSterling,
  faCircleXmark as farCircleXmark,
  faClipboard as farClipboard,
  faClock as farClock,
  faCommentSms as farCommentSms,
  faCopy as farCopy,
  faDash as farDash,
  faDownload as farDownload,
  faEnvelope as farEnvelope,
  faExclamationCircle as farExclamationCircle,
  faEye as farEye,
  faFileArrowDown as farFileArrowDown,
  faFileArrowUp as farFileArrowUp,
  faFileCertificate as farFileCertificate,
  faFileCircleCheck as farFileCircleCheck,
  faFileCircleExclamation as farFileCircleExclamation,
  faFileCirclePlus as farFileCirclePlus,
  faFileCircleQuestion as farFileCircleQuestion,
  faFileCircleXmark as farFileCircleXmark,
  faFileContract as farFileContract,
  faFileImport as farFileImport,
  faFilePlus as farFilePlus,
  faFiles as farFiles,
  faFilter as farFilter,
  faFloppyDisk as farFloppyDisk,
  faGauge as farGauge,
  faGaugeMax as farGaugeMax,
  faGear as farGear,
  faHouse as farHouse,
  faHouseBuilding as farHouseBuilding,
  faHouseCircleCheck as farHouseCircleCheck,
  faHousePersonReturn as farHousePersonReturn,
  faInbox as farInbox,
  faKitchenSet as farKitchenSet,
  faLightbulb as farLightbulb,
  faLocationPlus as farLocationPlus,
  faLockKeyhole as farLockKeyhole,
  faMagnifyingGlass as farMagnifyingGlass,
  faMoneyBill as farMoneyBill,
  faOctagonXmark as farOctagonXmark,
  faPaperPlane as farPaperPlane,
  faPenToSquare as farPenToSquare,
  faPhone as farPhone,
  faPlus as farPlus,
  faReceipt as farReceipt,
  faRectangleHistoryCircleUser as farRectangleHistoryCircleUser,
  faRocket as farRocket,
  faSlidersSimple as farSlidersSimple,
  faSms as farSms,
  faSparkles as farSparkles,
  faSunBright as farSunBright,
  faTableList as farTableList,
  faTrash as farTrash,
  faTrashCan as farTrashCan,
  faUnlockKeyhole as farUnlockKeyhole,
  faUpload as farUpload,
  faUser as farUser,
  faUsers as farUsers,
  faUsersMedical as farUsersMedical,
  faXmark as farXmark,
  faFileSignature as farFileSignature,
  faSignature as farSignature,
  faBadgeCheck as farBadgeCheck,
  faStopwatch as farStopwatch,
  faFileMagnifyingGlass as farFileMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faCalendarCheck as fasCalendarCheck,
  faChartMixed as fasChartMixed,
  faChartPieSimple,
  faCircleDashed as fasCircleDashed,
  faClone,
  faDoorClosed as fasDoorClosed,
  faLocationPlus as fasLocationPlus,
  faMessageSms,
  faBadgeCheck as fasBadgeCheck,
  faStars as fasStars,
  faStopwatch as fasStopwatch,
} from '@fortawesome/pro-solid-svg-icons';

import { faClock } from '@fortawesome/free-regular-svg-icons';

import { faStar as falStar } from '@fortawesome/pro-light-svg-icons';

// This is important, we are going to let Nuxt worry about the CSS
library.add(
  // Solid
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  fasStopwatch,
  fasBadgeCheck,
  faBuilding,
  faCalendar,
  faChartColumn,
  faChartPie,
  faChartPieSimple,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowDown,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  farCircleSterling,
  faClock,
  faClone,
  faCloudArrowUp,
  faCopy,
  faDumbbell,
  faEllipsisH,
  faEnvelope,
  faExclamation,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileArrowDown,
  faFilter,
  faGear,
  faGift,
  faHouse,
  faInfo,
  faLink,
  faLock,
  faMagnifyingGlass,
  faMask,
  faMessageSms,
  faMoon,
  faMoneyBill,
  faPause,
  faPenToSquare,
  faPen,
  faPhone,
  faPlus,
  faRocket,
  faRotateLeft,
  faRotateRight,
  faSpinner,
  faStar,
  faTableList,
  faTimesCircle,
  faTrashCan,
  faTriangleExclamation,
  faUser,
  faUserGroup,
  faWrench,
  faXmark,
  // Regular
  farOctagonXmark,
  farCircleQuestion,
  farArrowDown,
  farArrowUp,
  farArrowRightFromBracket,
  farArrowsRotate,
  farArrowUpRightFromSquare,
  farBan,
  farBath,
  farBell,
  farBed,
  farBolt,
  farBook,
  farStopwatch,
  farBuilding,
  farBuildings,
  farBadgeCheck,
  farCalendar,
  farCalendars,
  farChartBar,
  farChartColumn,
  farChartLine,
  farChartMixed,
  farChartPie,
  farChartUser,
  farChevronDown,
  farChevronUp,
  farCircle,
  farCircleCheck,
  farCircleDashed,
  farCircleInfo,
  farCircleSterling,
  farCircleXmark,
  farClipboard,
  farClock,
  farCommentSms,
  farCopy,
  farDash,
  farDownload,
  farEnvelope,
  farExclamationCircle,
  farEye,
  farFileArrowDown,
  farFileArrowUp,
  farFileCertificate,
  farFileCircleCheck,
  farFileCircleExclamation,
  farFileCirclePlus,
  farFileCircleQuestion,
  farFileCircleXmark,
  farFileContract,
  farFileImport,
  farFileMagnifyingGlass,
  farFilePlus,
  farFiles,
  farFilter,
  farFloppyDisk,
  farInbox,
  farGauge,
  farGaugeMax,
  farGear,
  farHouse,
  farHouseBuilding,
  farHouseCircleCheck,
  farHousePersonReturn,
  farKitchenSet,
  farLightbulb,
  farLockKeyhole,
  farLocationPlus,
  farMagnifyingGlass,
  farMoneyBill,
  farPaperPlane,
  farPenToSquare,
  farPhone,
  farPlus,
  farReceipt,
  farRectangleHistoryCircleUser,
  farRocket,
  farSlidersSimple,
  farSms,
  farSparkles,
  farSunBright,
  farTableList,
  farTrash,
  farTrashCan,
  farUpload,
  farUnlockKeyhole,
  farUser,
  farUsers,
  farUsersMedical,
  farXmark,
  // Solid
  fasBath,
  fasBed,
  fasCalendarCheck,
  fasDoorClosed,
  fasEllipsisVertical,
  fasHouseSignal,
  fasSterlingSign,
  fasUpload,
  fasPlay,
  fasFilePdf,
  fasChartMixed,
  fasCircleDashed,
  fasEquals,
  fasLocationPlus,
  farFileSignature,
  farSignature,
  fasAward,
  falStar,
  fasStars,
);

config.autoAddCss = false;

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon);
});
