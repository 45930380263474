import { helpers } from '@vuelidate/validators';
import { Delta } from '@vueup/vue-quill';
import dayjs, { type ConfigType } from 'dayjs';

export const mustBeTrue = (value: unknown): boolean => value === true;
export const isBoolean = (value: unknown) => typeof value === 'boolean';

export const UK_MIN_LATITUDE = 49;
export const UK_MAX_LATITUDE = 61;
export const UK_MIN_LONGITUDE = -8;
export const UK_MAX_LONGITUDE = 2;

export const postcode = helpers.regex(
  /^(X99BU)|(X9 9BU)|(GIR 0AA)|(((A[BL]|B[ABDHLNRSTX]?|C[ABFHMORTVW]|D[ADEGHLNTY]|E[HNX]?|F[KY]|G[LUY]?|H[ADGPRSUX]|I[GMPV]|JE|K[ATWY]|L[ADELNSU]?|M[EKL]?|N[EGNPRW]?|O[LX]|P[AEHLOR]|R[GHM]|S[AEGKLMNOPRSTY]?|T[ADFNQRSW]|UB|W[ADFNRSV]|YO|ZE)[1-9]?[0-9]|((E|N|NW|SE|SW|W)1|EC[1-4]|WC[12])[A-HJKMNPR-Y]|(SW|W)([2-9]|[1-9][0-9])|EC[1-9][0-9]) [0-9][ABD-HJLNP-UW-Z]{2})$/i,
);

export const humanName = helpers.regex(
  /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/g,
);

export const ukMobileNumber = helpers.regex(
  /^((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}$/,
);

export const multipleCSVEmails = helpers.regex(
  /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/,
);

export const validUrl = (
  value: string | null | undefined | unknown,
): boolean => {
  // Return false if value is null or undefined
  if (value === null || value === undefined) {
    return false;
  }

  const stringValue = typeof value === 'string' ? value : String(value);

  // URL validation logic - must be a fully qualified URL with protocol
  try {
    const url = new URL(stringValue);

    // Ensure both hostname and protocol exist
    if (!url.hostname || !url.protocol) {
      return false;
    }

    // Check for a valid hostname structure (must have at least one dot and a TLD)
    const hostnameParts = url.hostname.split('.');
    return !(
      hostnameParts.length < 2 ||
      hostnameParts[hostnameParts.length - 1].length === 0
    );
  } catch {
    return false;
  }
};

export const notBeQuillEmptyState = (
  value: Delta | string | null | undefined,
): boolean => {
  if (!value) return false;
  const emptyStates = ['<p><br></p>', '<p></p>', ''];
  return !emptyStates.includes(value.toString().trim());
};

export const isValidDate = (value: ConfigType) =>
  dayjs(value, 'YYYY-MM-DD', true).isValid();

export const afterDate = (date: ConfigType) =>
  helpers.withParams({ type: 'after', value: date }, (value: ConfigType) =>
    dayjs(value).isAfter(date),
  );

export const afterStartDate = (startDate: ConfigType) =>
  helpers.withMessage(
    'The end date cannot be before the start date',
    afterDate(startDate),
  );

export const notZero = (index: ConfigType) => index !== 0;

export const isLatitudeSimple = (num: number) =>
  isFinite(num) && num >= UK_MIN_LATITUDE && num <= UK_MAX_LATITUDE;
export const isLongitudeSimple = (num: number) =>
  isFinite(num) && num >= UK_MIN_LONGITUDE && num <= UK_MAX_LONGITUDE;

export const requiredUnlessToggled = (
  value: number | string | null,
): boolean => {
  if (value === null) return true;
  const numValue = typeof value === 'string' ? Number(value) : value;
  return !isNaN(numValue) && numValue > 0;
};

export const positiveNumber = (value: number | null): boolean => {
  if (value === null) return false;
  return typeof value === 'number' && value > 0;
};
