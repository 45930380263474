// @modules/notifications/stores/index.ts
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { captureException, setContext } from '@sentry/vue';
import type { NotificationData, NotificationId } from '@notifications/types';

export const useNotificationsStore = defineStore(
  'notifications',
  () => {
    //   const { cdnAsset } = useHelpers();
    const toaster = useToaster();
    //   const bellSound = new Audio(cdnAsset('/audio/bell.mp3'));
    const dayjs = useDayjs();

    const isOpen = ref<boolean>(false);
    const lastSeenAt = useStorage('lastSeenAt', dayjs());
    const notifications = ref<NotificationData[]>([]);
    const meta = ref();

    const unreadCount = computed(() => {
      const lastSeenAtDayJs = dayjs(lastSeenAt.value);

      let filtered = [];

      try {
        filtered = notifications.value.filter((item) => {
          return !item.isRead && dayjs(item.createdAt).isAfter(lastSeenAtDayJs);
        });
      } catch (e) {
        setContext('notificationData', notifications.value);
        captureException(e);
        return 0;
      }

      return filtered.length;
    });

    const hasMoreResults = computed(() => {
      return meta.value.current_page !== meta.value.last_page;
    });

    async function getNotifications() {
      const { data } = await notificationsApi.get();

      if (data.value) {
        notifications.value = data.value.data;
        meta.value = data.value.meta;
      }
    }

    async function fetchNextPage() {
      const page = meta.value.current_page + 1;

      const { data } = await notificationsApi.get({ page });

      if (data.value) {
        notifications.value.push(...data.value.data);
        meta.value = data.value.meta;
      }
    }

    async function markAsRead(id: NotificationId): Promise<void> {
      const response = await notificationsApi.update(id);

      if (response.status.value === 'success') {
        notifications.value.map((notification) => {
          if (notification.id === id) {
            notification.isRead = true;
          }
          return notification;
        });
      }
    }

    async function markAllAsRead() {
      const response = await notificationsApi.markAllAsRead();

      // Update all notifications we have in the store as read
      if (response.status.value === 'success') {
        notifications.value.map((notification) => {
          notification.isRead = true;
          return notification;
        });
      }
    }

    async function handleNewEnquiryNotification() {
      await getNotifications();
      // await playNotification();
      toaster.add('success', '✨ New enquiry received!');
    }

    //   async function playNotification() {
    //     await bellSound.play();
    //   }

    function updateLastSeenAt() {
      lastSeenAt.value = dayjs();
    }

    return {
      isOpen,
      notifications,
      meta,
      unreadCount,
      lastSeenAt,
      hasMoreResults,

      handleNewEnquiryNotification,
      getNotifications,
      markAsRead,
      markAllAsRead,
      updateLastSeenAt,
      fetchNextPage,
    };
  },
  {
    persist: {
      paths: [
        'isOpen',
        'notifications',
        'meta',
        'unreadCount',
        'lastSeenAt',
        'hasMoreResults',
      ],
      storage: sessionStorage,
    },
  },
);
